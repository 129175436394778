/* VGA9 font is loaded in index.css, so it should never need a fallback */
.entity-name p {
  margin: 0;
  padding: 0; }
  .entity-name p .held-name {
    color: #808080;
    text-shadow: -2px 0 #000000, 0 2px #000000, 2px 0 #000000, 0 -2px #000000; }

.entity-name .original {
  font-size: 1.6em;
  font-family: VGA9, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 2px;
  cursor: pointer; }
  @media only screen and (max-width: 600px) {
    .entity-name .original {
      font-size: 2em; } }
  @media only screen and (max-width: 500px) {
    .entity-name .original {
      font-size: 2.5em; } }

.entity-name .translated {
  height: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.7em;
  margin-bottom: 10px; }

.entity-module {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
  border: 0px solid #9e9e9e; }
  .entity-module .entity-module-controls {
    display: flex;
    flex-direction: row;
    border: 0px solid cyan;
    justify-content: center;
    align-items: center; }
    .entity-module .entity-module-controls .button-entity-name {
      margin: 0 5px; }
  @media only screen and (max-width: 500px) {
    .entity-module {
      width: 100vw;
      margin: auto;
      padding: 0; } }
