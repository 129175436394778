/* Gets rid of that irritating dotted border in Firefox */
a:active { 
  outline: none; 
}  
a:focus { 
  -moz-outline-style: none;
  outline-style: none; 
}

@font-face {
  font-family: 'VGA9';
  font-style: normal;
  font-weight: 400;
  src: url('./components/assets/PxPlus_IBM_VGA9.ttf'); /* IE9 Compat Modes */
  src: local('VGA9'), local('VGA9'),
       url('./components/assets/PxPlus_IBM_VGA9.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* body {
  margin: 0;
  padding: 0;
  font-family: VGA9, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: VGA9, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}