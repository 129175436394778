/* Gets rid of that irritating dotted border in Firefox */
a:active { 
  outline: none; 
}  
a:focus { 
  -moz-outline-style: none;
  outline-style: none; 
}

@font-face {
  font-family: 'VGA9';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/PxPlus_IBM_VGA9.33e0c189.ttf); /* IE9 Compat Modes */
  src: local('VGA9'), local('VGA9'),
       url(/static/media/PxPlus_IBM_VGA9.33e0c189.ttf) format('truetype'), /* Safari, Android, iOS */
}

/* body {
  margin: 0;
  padding: 0;
  font-family: VGA9, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: VGA9, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* VGA9 font is loaded in index.css, so it should never need a fallback */
.entity-name p {
  margin: 0;
  padding: 0; }
  .entity-name p .held-name {
    color: #808080;
    text-shadow: -2px 0 #000000, 0 2px #000000, 2px 0 #000000, 0 -2px #000000; }

.entity-name .original {
  font-size: 1.6em;
  font-family: VGA9, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 2px;
  cursor: pointer; }
  @media only screen and (max-width: 600px) {
    .entity-name .original {
      font-size: 2em; } }
  @media only screen and (max-width: 500px) {
    .entity-name .original {
      font-size: 2.5em; } }

.entity-name .translated {
  height: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.7em;
  margin-bottom: 10px; }

.entity-module {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 30px;
  border: 0px solid #9e9e9e; }
  .entity-module .entity-module-controls {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    border: 0px solid cyan;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .entity-module .entity-module-controls .button-entity-name {
      margin: 0 5px; }
  @media only screen and (max-width: 500px) {
    .entity-module {
      width: 100vw;
      margin: auto;
      padding: 0; } }

.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.text-minor {
  font-size: 12px;
  color: #FFFFFF;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.modal-buttonbox button {
  border-radius: 5px;
  margin: 0 10px;
  border-width: 2px; }

.token-list-head {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  border-bottom: 2px solid #9c9c9c85;
  background-color: #000000;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  .token-list-head select {
    margin: 3px 3px 3px 0; }

.token-list {
  width: 70vw;
  max-width: 600px;
  height: 90vh;
  margin: auto;
  margin-top: 5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #000000; }
  .token-list:focus {
    outline: none; }
  .token-list .token-toggle-row {
    border-bottom: 1px solid #9c9c9c85;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 20px; }
    .token-list .token-toggle-row span {
      color: white;
      line-height: 30px;
      font-size: 30px;
      font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.trinary-toggle {
  height: 30px;
  margin: 2px 0;
  width: 100%;
  background-color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none; }
  .trinary-toggle:focus {
    outline: none; }
  .trinary-toggle::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 1rem;
    background: #CCCCCC;
    border: 1px solid #000000; }
  .trinary-toggle::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 1rem;
    background: #CCCCCC;
    border: 0px solid #000000; }
  .trinary-toggle::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 1rem;
    background: #CCCCCC;
    width: 100px;
    border-color: transparent;
    color: transparent;
    border-width: 10px 0; }
  .trinary-toggle::-webkit-slider-thumb {
    border: 1px solid #00001E;
    height: 28px;
    width: 50px;
    border-radius: 1rem;
    background: #F0F0F0;
    cursor: pointer;
    -webkit-appearance: none; }
  .trinary-toggle::-moz-range-thumb {
    border: 1px solid #00001E;
    height: 28px;
    width: 50px;
    border-radius: 1rem;
    background: #F0F0F0;
    cursor: pointer; }
  .trinary-toggle::-ms-thumb {
    margin-top: 1px;
    border: 1px solid #00001E;
    height: 28px;
    width: 50px;
    border-radius: 1rem;
    background: #F0F0F0;
    cursor: pointer; }

.trinary-toggle-red::-webkit-slider-runnable-track {
  background: #d32f2f; }

.trinary-toggle-red::-moz-range-track {
  background: #d32f2f; }

.trinary-toggle-red::-ms-track {
  background: #d32f2f; }

.trinary-toggle-green::-webkit-slider-runnable-track {
  background: #43a047; }

.trinary-toggle-green::-moz-range-track {
  background: #43a047; }

.trinary-toggle-green::-ms-track {
  background: #43a047; }

.trinary-toggle-default::-webkit-slider-runnable-track {
  background: #CCCCCC; }

.trinary-toggle-default::-moz-range-track {
  background: #CCCCCC; }

.trinary-toggle-default::-ms-track {
  background: #CCCCCC; }

@media only screen and (max-width: 500px) {
  .token-list {
    width: 90vw;
    height: 90vh; }
    .token-list .token-toggle-row {
      padding: 0px 0px; } }

