//importing color variables
@import 'src/colors.scss';
$font-pixel: VGA9;
$inner-border: 0px solid cyan;
$outer-border: 0px solid #9e9e9e;


/* VGA9 font is loaded in index.css, so it should never need a fallback */
.entity-name {
  p {
    margin: 0;
    padding: 0;
    .held-name {
      color: $dgray;
      text-shadow: -2px 0 $black, 0 2px $black, 2px 0 $black, 0 -2px $black;
    }
  }
  .original {
    font-size: 1.6em;
    font-family: $font-pixel, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin-bottom: 2px;
    cursor: pointer;
    @media only screen and (max-width : 600px){
      font-size: 2em;
    }
    @media only screen and (max-width : 500px){
      font-size: 2.5em;
    }
  }
  .translated {
    height: 16px; // keeps the div the same height even if there's no text in it
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 0.7em;
    margin-bottom: 10px;
  }
}

.entity-module {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
  border: $outer-border;
  .entity-module-controls {
    display: flex;
    flex-direction: row;
    border: $inner-border;
    justify-content: center;
    align-items: center;
    .button-entity-name {
      margin: 0 5px;
    }
  }
  @media only screen and (max-width : 500px){
    width: 100vw;
    margin: auto;
    padding: 0;
  }
}

