
$red: #d32f2f;
$green: #43a047;
$grey: #CCCCCC;
$white: #FFFFFF;
$black: #000000;
$grey-light: #9c9c9c85;

@mixin track {
  width: 100%;
  height: 30px;
  cursor: pointer;
  border-radius: 1rem;
  background: $grey;
}

@mixin nub {
  border: 1px solid #00001E;
  height: 28px;
  width: 50px;
  border-radius: 1rem;
  background: #F0F0F0;
  cursor: pointer;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row {
  @include flex-row;
}

.text-minor {
  font-size: 12px;
  color: $white;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.modal-buttonbox {
  button {
    border-radius: 5px;
    margin: 0 10px;
    // color: $white;
    // background-color: $black;
    border-width: 2px;
  }
}

.token-list-head {
  @include flex-row;
  justify-content: space-around;
  border-bottom: 2px solid $grey-light;
  background-color: $black;
  position: sticky;
  top: 0;
  select {
    // color: $white;
    // background: $black;
    margin: 3px 3px 3px 0;
  }
}

.token-list {
  width: 70vw;
  max-width: 600px;
  height: 90vh;
  margin: auto;
  margin-top: 5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: $black;
  &:focus {
    outline: none;
  }
  .token-toggle-row {
    border-bottom: 1px solid $grey-light;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    span {
      color: white;
      line-height: 30px;
      font-size: 30px;
      font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
  }
}

.trinary-toggle {
  height: 30px;
  margin: 2px 0;
  width: 100%;
  background-color: $black;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: none;
  }

  // style slider track. one for each major browser
  &::-webkit-slider-runnable-track {
    @include track;
    border: 1px solid $black;
  }
  &::-moz-range-track {
    @include track;
    border: 0px solid $black;
  }
  &::-ms-track {
    @include track;
    width: 100px;
    border-color: transparent;
    color: transparent;
    border-width: 10px 0;
  }

  // style slider nub. one for each major browser
  &::-webkit-slider-thumb {
    @include nub;
    -webkit-appearance: none;
  }
  &::-moz-range-thumb {
    @include nub;
  }
  &::-ms-thumb {
    margin-top: 1px;
    @include nub;
  }
}

.trinary-toggle-red{
  &::-webkit-slider-runnable-track{background: $red;}
  &::-moz-range-track {background: $red;}
  &::-ms-track {background: $red;}
}
.trinary-toggle-green{
  &::-webkit-slider-runnable-track{background: $green;}
  &::-moz-range-track {background: $green;}
  &::-ms-track {background: $green;}
}
.trinary-toggle-default{
  &::-webkit-slider-runnable-track{background: $grey;}
  &::-moz-range-track {background: $grey;}
  &::-ms-track {background: $grey;}
}

@media only screen and (max-width : 500px){
  .token-list {
    width: 90vw;
    height: 90vh;
    .token-toggle-row{
      padding: 0px 0px;
    }
  }
}